.dm-spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dm-text {
    color: black;
}

.dm-btn-wrapper {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
}

.dm-btn {
    width: 350px;
}

.dm-select {
    width: 90px;
}