/* font-family: 'Titillium Web', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

body {
  color: white;
  background-color: black;
}

.firebase-emulator-warning {
  opacity: 0.0;
  pointer-events: none;
}

.app-font {
  font-family: 'Titillium Web', sans-serif;
}

.app-modal {
  color: black;
}

.app-bold {
  font-weight: bold;
}