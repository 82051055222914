.hp-page {
    background-image: linear-gradient(rgb(46, 44, 48), rgb(10, 10, 10));
    min-height: 100vh;
}

.hp-banner-logo {
}

.hp-section {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 10px;
}

.hp-main-desc-section {
    padding: 25px;
}

.hp-main-desc-text {
    padding: 0px;
    margin: 0px;
    font-size: 1rem;
}

.hp-try-section {
    display: none;
}

.hp-section-title {
    text-align: center;
    font-weight: bold;
    margin: 0px;
}

.hp-section-text {
    text-align: center;
    padding: 0px;
    margin: 0px;
}

.hp-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hp-link-btn {
    /* width: 200px; */
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3rem;
}

.hp-feature-title {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 0px 0px 20px rgb(169, 98, 255);
}

/* Got this iframe code from https://embedresponsively.com/ */
.hp-embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 

.hp-embed-container iframe, 
.hp-embed-container object, 
.hp-embed-container embed { 
    position: absolute; 
    top: 0; left: 0; 
    width: 100%; height: 100%; 
}

.hp-feature-desc-blocks {
    padding: 20px;
    padding-bottom: 0px;
}

.hp-feature-desc-block {
    padding: 0px;
    margin-bottom: 20px;
}

.hp-feature-desc-title {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0px;
    margin: 0px;
}

.hp-feature-desc-text {
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
    font-size: 1rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .hp-section { 
        padding: 20px;
        border-bottom-style: none;
    }

    .hp-main-desc-section {
        display: flex;
        justify-content: center;
    }

    .hp-main-desc-text {
        width: 640px;
    }

    .hp-try-section {
        padding-top: 20px;
        padding-bottom: 50px;
        display: block;
    }

    .hp-feature-title {
        font-size: 2rem;
        text-align: left;
    }
    .hp-feature-content {
        display: flex;
        width: 100%;
    }

    .hp-demo-vid {
        width: 50%;
    }

    .hp-feature-desc-blocks {
        padding-top: 0px;
        width: 50%;
    }
}


@media only screen and (min-width: 992px) {
    .hp-section {
        padding: 40px;
    }

    .hp-try-content-wrapper {
        background-color: rgba(128, 128, 128, 0.507);
        width: 300px;
        padding: 15px;
        margin-top: 0px;
        border-radius: 10px;
        box-shadow: 2px 0px 25px black;
        transition: transform 0.2s ease-in-out;
    }

    .hp-try-content-wrapper:hover {
        transform: scale(1.1, 1.1);
    }

    .hp-try-section {
        display: flex;
        justify-content: center;
        padding: 0px;
        padding: 10px;
    }

    .hp-feature-title {
        font-size: 2.5rem;
    }
    .hp-demo-vid {
        width: 30%;
    }

    .hp-feature-desc-blocks {
        width: 70%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .hp-feature-desc-block {
        width: 40%;
    }
}


@media only screen and (min-width: 1200px) {
}
