.w-page {
    padding: 20px;
    height: 100vh;
    background-color: rgb(31, 23, 63);
}

.w-title {
    font-weight: bold;
}

.w-description {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 5px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .w-page {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .w-content {
        width: 500px;
    }

    .w-title {
        text-shadow: 0px 0px 20px rgb(169, 98, 255);
    }

    .w-btn-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
