.vb-main {
    width: 100vw;
    overflow: hidden;
    background-color: pink;
}

.vb-video-container {
    overflow: hidden;
    width: 150%;
    display: flex;
    justify-content: center;
    position: relative;
    left: -25%;
    background-color: white;
}

.vb-video {
    object-fit: cover;
    object-position: 0px 0px;
    background-color: rgb(0, 0, 0);
    width: 770px;
}

.vb-title-anchor {
    height: 0px;
}

.vb-title-container {
    position: relative;
    top: -250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.692);
}

.vb-video-wrapper, .vb-video, .vb-title-container {
    height: 250px;
}

.vb-title {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    text-shadow: 0px 0px 20px rgb(169, 98, 255);
    display: flex;
    align-items: flex-start;
    gap: 10px;
    justify-content: center;
}

.vb-subtitle {
    font-size: 17px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {    
    .vb-video {
        width: 995px;
    }
    
    .vb-title-container {
        top: -300px;
    }
    
    .vb-video-container, .vb-video, .vb-title-container {
        height: 300px;
    }
    
    .vb-title {
        font-size: 80px;
    }

    .vb-subtitle {
        font-size: 25px;
    }
}


@media only screen and (min-width: 992px) {
    .vb-video {
        width: 1200px;
    }
}


@media only screen and (min-width: 1200px) {
    .vb-video {
        width: 2000px;
    }
}
