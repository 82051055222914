.octave {
    /* height: 127px; */
}

.wk-container {
    position: relative;
    height: 0px;
    box-shadow: 3px 3px 8px black;
}

.key {
    padding: 0px;
    position: relative;
    /* width: 22px; */
    /* height: 126.27px; */
    border-style: solid;
    border-width: thin;
    margin: 0px;
    overflow: hidden;
    border-color: black;
    box-shadow: 4px 0px 8px black;
    cursor: pointer;
}

.key-mask-anchor {
    width: 0px;
    position: absolute;
}

.key-mask {
    position: relative;
    z-index: 10;
    /* display: none; */
    cursor: pointer;
}

.key-mask:hover {
    background-color: rgba(255, 0, 0, 0.356);
}

.key-mask:active {
    background-color: rgba(255, 0, 0, 0.671);
}

.key-mask-fill {
    fill: rgba(255, 255, 255, 0);
}

.bk-container {
    position: relative;
    height: 0px;
}

.bk-wrapper {
    position: relative;
    height: 0px;
}

.blackKey {
    position: relative;
    /* width: 14px; */
    /* height: 80px; */
    border-style: solid;
    border-width: thin;
    border-color: black;
    z-index: 11;
}

.blackKey-mask-anchor {
    width: 0px;
    height: 0px;
    position: absolute;
}

.blackKey-mask {
    position: relative;
    z-index: 12;
    background-color: rgba(177, 34, 34, 0);
    cursor: pointer;
}

.blackKey-mask:hover {
    background-color: rgba(255, 47, 47, 0.616);
}

.blackKey-mask:active {
    background-color: rgba(102, 0, 0, 0.685);
}

.kb-cNum {
    color: rgb(138, 138, 138);
    position: relative;
}
