.auth-spinner-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-gray {
    background-color: rgb(39, 44, 49);
}

.loading-blank {
    background-color: rgba(255, 255, 255, 0);
}

.auth-spinner {
    margin: 10px;
}
  
/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
