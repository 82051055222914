.mp-page {
    display: none;
}

.mp-page-sm {
    width: 100vw;
    height: 100vh;
    background-color:rgb(31, 23, 63);
}

.mp-title-button, .mp-title-button:hover, .mp-title-button:active {
    background-color: rgba(255, 255, 255, 0);
    border-style: none;
    text-decoration: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
    .mp-page-sm {
        display: none;
    }

    .mp-page {
        display: block;
        height: 100vh;
        background-image: linear-gradient(rgb(45, 33, 92), rgb(21, 16, 43));
        padding-top: 20px;
    }
    
    .mp-header {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        flex-wrap: wrap;
    }
    
    .mp-title {
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        display: flex;
        margin-left: 20px;
        gap: 5px;
        cursor: pointer;
        text-shadow: 0px 0px 10px rgb(169, 98, 255);
        min-width: 240px;
        margin-bottom: 0px;
        color: white;
    }
    
    .mp-keyboard-wrapper {
        width: 100vw;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    
    .mp-keyboard {
        /* position: absolute; */
        bottom: 0;
    }
    
    
    .mp-settings-container {
        margin-left: 20px;
        margin-bottom: 10px;
        display: flex;
        padding-top: 15px;
        flex-wrap: wrap;
    }
    
    .mp-setting-section {
        display: flex;
        margin-bottom: 20px;
    }
    
    .mp-setting {
        margin-right: 30px;
        margin-bottom: 15px;
    }
    
    .mp-setting-title {
        font-size: 1rem;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
    }
    
    .mp-setting-val {
        width: 60px;
        padding: 0px;
        margin: 0px;
        text-align: center;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
    }
    
    .mp-switch-title {
        margin-left: 40px;
    }
    
    .mp-octave-setting {
        width: 35px;
    }
    
    .mp-setting-btns {
        display: flex;
    }
    
    .mp-setting-btn {
        border-radius: 50%;
        background-color: rgb(0, 164, 170);
        color: white;
        border-style: none;
    }
    
    .mp-setting-btn:hover {
        background-color: rgb(0, 133, 138);
    }
    
    .mp-setting-btn:focus {
        background-color: rgb(0, 108, 112);
    }
    
    .mp-setting-btn, .mp-setting-btn:hover, .mp-setting-btn:focus {
        transition-duration: 0.2s;
    }
    
    .mp-chord-container {
        display: flex;
        gap: 15px;
    }
    
    .mp-chord-title {
        font-size: 2rem;
    }
    
    .mp-chord-text-container {
        width: 180px;
        height: 70px;
        background-color: black;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .mp-chord-text {
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        font-size: 2rem;
    }
}


@media only screen and (min-width: 1200px) {
}
