.kb-wrapper {
    background-color: rgb(49, 49, 49);
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgb(0, 0, 0);
}

.kb-text {
    margin-bottom: 10px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: bold;
}

.kb-channel-section {
    display: flex;
}

.kb-channel {
}

.keyboard {
    display: flex;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
